<template>
  <div class="outlet-item" @click="toDetail">
    <div class="outlet-item__top">
      <div class="outlet-item__wrap">
        <van-image class="outlet-item__cover-img" :src="item.coverImg"/>
        <van-tag class="outlet-item__cover-tag" color="#fb6646" type="primary" v-if="item.vrUrl != null">VR全景浏览
        </van-tag>
      </div>
      <div class="outlet-item__info">
        <div class="outlet-item__info-row">
          <div class="outlet-item__info-title">
            {{ item.storeName }}
          </div>
        </div>
        <div class="outlet-item__info-row" v-if="tags.length > 0 ">
          <div class="outlet-item__info-tag  outlet-item__info-left">
            <van-tag type="primary" :key="index" v-for="(item, index) in tags">{{ item }}</van-tag>
          </div>
        </div>
        <div class="outlet-item__info-row">
          <div class="outlet-item__info-tag  outlet-item__info-left">
            <div class="shop-price" v-if="item.minPrice">
              <span>¥</span>
              <span class="shop-price-text">{{ Math.trunc(item.minPrice) }}</span>
              <span>.{{ (item.minPrice % 1).toFixed(2).split(".")[1] }}</span>
            </div>
          </div>
        </div>
        <div class="outlet-item__info-row">
          <div class="text-ellipsis outlet-item__info-address  outlet-item__info-left">
            <van-icon name="location" color="#fb6646"/>
            {{ item.address }}
          </div>
          <div class="outlet-item__info-distance outlet-item__info-right">
            {{ item.distance }}KM
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Image, Icon, Tag} from 'vant';
import {mapState} from 'vuex';

export default {
  name: "outletItem",
  props: {item: Object},
  components: {
    vanImage: Image,
    vanIcon: Icon,
    vanTag: Tag
  },
  data() {
    return {
      coverTagVR: "https://cym.bestweixiao.com/vr.png"
    }
  },
  computed: {
    ...mapState({
      location: state => state.app.location,
    }),
    tags() {
      return this.item.tags ? this.item.tags.split("|") : [];
    },
  },
  methods: {
    toDetail() {
      this.$router.push(`/store/${this.item.id}`);
    }
  }
}
</script>

<style lang="less" type="text/less">
@import "../../assets/less/variable";
@import "../../assets/less/utils";

.outlet-item {
  display: flex;
  flex-direction: column;

  padding: 15px;
  box-sizing: border-box;
  position: relative;
  background: @white;

  color: #333;

  &__top {
    display: flex;
  }

  &__wrap {
    position: relative;
  }

  &__cover-tag {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__cover-img {
    width: 110px;
    height: 90px;
    border-radius: 8px;

    img {
      width: 110px;
      height: 90px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    flex: 1;

    &-row {
      display: flex;
      height: 30px;
      justify-content: space-between;

      .shop-price {
        margin-top: 5px;
        font-size: 12px;
        color: #ff5a5f;
        height: 100%;
        span{
          font-size: 14px;
          font-weight: bold;
        }
      }
      .shop-price-text {
        font-size: 16px;
      }
    }

    &-title {
      font-size: 13px;
      line-height: 15px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }

    &-left {
      width: 70%;
    }

    &-right {
      width: 30%;
    }

    &-address, &-distance {
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      .textOverflow();
    }

    &-address {
      max-width: 150px;
      margin-right: 10px;
    }


  }
}

.outlet-item ~ .outlet-item {
  margin-top: 10px;
}
</style>

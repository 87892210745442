<template>
  <div class="page">
    <div class="main">
      <van-cell-group id="filter-bar">
        <van-row class="filter-bar" type="flex" align="center" justify="space-around">
          <van-col class="filter-bar__condition" span="6" @click="() => {this.areaPopupShow = true}">
            {{ this.location.province }}
          </van-col>
          <van-col class="filter-bar__condition" span="6" @click="() => {this.areaPopupShow = true}">
            {{ this.location.city }}
          </van-col>
<!--          <van-col class="filter-bar__condition" span="6">-->
<!--            <van-dropdown-menu >-->
<!--              <van-dropdown-item @change="orderByTypeChange" v-model="orderByType" :options="serviceList"/>-->
<!--            </van-dropdown-menu>-->
<!--          </van-col>-->
        </van-row>
        <van-row>
          <van-checkbox-group style="justify-content: space-around" @change="checkBoxGroupChange" v-model="sortType" direction="horizontal">
            <van-checkbox style="font-size: 14px" checked-color="#fb6646" name="price">按价格排序</van-checkbox>
            <van-checkbox style="font-size: 14px" checked-color="#fb6646" name="distance">按距离排序</van-checkbox>
          </van-checkbox-group>
        </van-row>
      </van-cell-group>


      <outlet :key="item.merchantId" :item="item" v-for="item in outletList.data"/>


      <van-popup v-model="areaPopupShow" position="bottom">
        <van-area :area-list="areaList" columns-num="2" @confirm="areaConfirm"
                  @cancel="() => {this.areaPopupShow = false}"/>
      </van-popup>
    </div>
  </div>
</template>

<script>
import {CellGroup, Row, Col, Popup, Area, Checkbox,CheckboxGroup} from 'vant';
import areaList from '../config/areaList';
import {isWeChatBrowser} from '../utils/browserUtil';
import {listStoreService} from "../service/store";
import outlet from "../components/Outlet/outletItem";
import {computeDistance} from '../utils/geoUtil'
import {mapState} from "vuex";

export default {
  name: "nearby",
  components: {
    vanRow: Row,
    vanCol: Col,
    vanCellGroup: CellGroup,
    vanPopup: Popup,
    vanArea: Area,
    outlet,
    vanCheckbox:Checkbox,
    vanCheckboxGroup:CheckboxGroup,

  },
  data() {
    return {
      areaList,
      areaPopupShow: false,
      outletList: {
        loading: false,
        finished: true,
        data: [],
        page: 1,
        size: 99
      },
      sortType:[],
      orderByType: 1,
      serviceList: [
        {text: '按距离排序', value: 1},
        {text: '按价格排序', value: 2},
      ]
    }
  },
  computed: {
    ...mapState({
      location: state => state.app.location
    })
  },
  methods: {
    amapGeoCode(longitude, latitude) {
      const _this = this;
      this.$http.get(`https://restapi.amap.com/v3/geocode/regeo?key=0e845ebbb70f14b4c846e5fb0982048f&location=${longitude},${latitude}`)
          .then(resp => {
            if (resp.info === "OK") {
              const address = resp.regeocode.addressComponent;
              _this.$store.commit("CURRENT_CITY", {
                province:address.province,
                city:address.city,
                district:address.district,
                longitude,
                latitude,
                areaCode:address.adcode.substring(0,4),
                formatAddress:resp.regeocode.formatted_address,
              })
              _this.loadOutletList();
            }
          })
    },
    checkBoxGroupChange(names){
      if(names.length === 2){
        this.orderByType = 3;
      } else if(names.length === 1){
        if(names[0] === 'price'){
          this.orderByType = 2;
        } else if(names[0] === 'distance'){
          this.orderByType = 1;
        }
      }
      this.orderByTypeChange();
    },
    orderByTypeChange() {
      if (this.orderByType === 1) {
        this.outletList.data = this.outletList.data.sort((first, second) => {
          return first.distance - second.distance;
        });
      } else if (this.orderByType === 2) {
        this.outletList.data = this.outletList.data.sort((first, second) => {
          if(!first.minPrice){
            return 1;
          } else if(!second.minPrice){
            return -1;
          } else {
            return first.minPrice - second.minPrice;
          }
        });
      } else if(this.orderByType === 3){
        this.outletList.data = this.outletList.data.sort((first, second) => {
          if(!first.minPrice){
            return 1;
          } else if(!second.minPrice){
            return -1;
          } else {
            if(first.minPrice - second.minPrice === 0){
              return first.distance - second.distance;
            } else {
              return first.minPrice - second.minPrice;
            }
          }
        });
      }

    },
    areaConfirm(areaArr) {
      const location = {...this.location};
      location.province = areaArr[0].name;
      location.city = areaArr[1].name;
      location.district = areaArr[0].name;
      this.$store.commit("CURRENT_CITY", {...location})
      this.outletList.page = 0;
      this.outletList.size = 99;
      this.outletList.finished = false;
      this.outletList.data = [];
      this.loadOutletList();
      this.areaPopupShow = false;
    },
    loadOutletList() {
      const _this = this;
      const params = {
        page: this.outletList.page,
        size: this.outletList.size,
        categories: [3],
        city: this.location.areaCode,
        channelId: 4,
      };
      listStoreService(params).then(resp => {
        if (resp.success) {
          const data = this.outletList.data.concat(resp.data);
          console.log({
            longitude:_this.location.longitude,
            lat:_this.location.latitude,
          })
          const newData = data.map(item => {
            const distance = computeDistance({
              longitude: _this.location.longitude,
              latitude: _this.location.latitude,
            }, {
              longitude: item.lng,
              latitude: item.lat,
            });
            return {
              ...item,
              distance
            }
          });
          newData.sort((first, second) => {
            return first.distance - second.distance;
          });
          this.outletList.data = newData;
        } else {
          this.outletList.finished = true;
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.outletList.loading = false;
      })
    },
  },
  mounted() {
    this.loadOutletList();

    this.$wechat.ready(() => {
      this.$wechat.ready(() => {
        this.$wechat.hideAllNonBaseMenuItem();
      });
    });
  },
  created() {
    const _this = this;
    if (isWeChatBrowser()) {
      this.$wechat.ready(() => {
        _this.$wechat.getLocation({
          success: function (res) {
            console.log(res);
            _this.amapGeoCode(res.longitude, res.latitude);
          },
          fail: function (error) {
            console.error(error);
          }
        })
      })
    }
  }
}
</script>

<style scoped type="text/less" lang="less">
@import "../assets/less/variable";

.filter-bar {
  height: 40px;

  &__condition {
    text-align: center;
    font-size: @font-size-md;
  }
}

</style>
